a.icon-banners{text-decoration: none;}
.icon-banners{
  position: relative;
  padding: 50px 40px;
  color: var(--color-base);
}
.icon-banners:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-base);
  opacity: 0.3;
  border-radius: var(--radius);
}
.text-left .icon-banners{
  display: flex;
}
.text-left .icon-banners__icon{
  margin-bottom: 0;
  margin-right: 20px;
  flex: 0 0 auto;
}
.icon-banners__icon img{
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.grid-columns-2{
  --columns: 2;
}
.grid-columns-3{
  --columns: 3;
}
.grid-columns-4{
  --columns: 4;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: var(--page-padding);
}
@media (min-width: 993px){
  .icon-banners.scale-border-hover:before{
	transform: translate3d(0,0,0);
	transition: all var(--duration-medium) var(--zoom-animation-bezier);
  }
  .icon-banners.scale-border-hover:hover:before{
	--scale-on: 20px;
	--move-x: calc(var(--scale-on) / 2 * -1);
	--move-y: calc(var(--scale-on) / 2 * -1);
	transform: translate3d(var(--move-x),var(--move-y),0);
	width: calc(100% + var(--scale-on));
	height: calc(100% + var(--scale-on));
  }
}
@media (max-width: 1200px){
  .grid-container{
	--columns: 2;
  }
}
@media (max-width: 576px){
  .grid-container{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
  }
  .grid-container.slider{
	flex-direction: row;
  }
  .slider .icon-banners{
	flex: 0 0 auto;
	width: 100%;
  }
}
@media (max-width: 576px){
  .icon-banners{
	padding: 40px 30px;
  }
  .icon-banners .btn-medium{
	margin-bottom: 7px;
  }
  .grid-container{
	--columns: 1;
  }
}